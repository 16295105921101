import React, { Children } from "react";
import PropTypes from "prop-types";
import StyledModal from "./StyledModal";
import Button from "../Button";

const handleModalClick = (e, id) => {
  const modalContainer = document.getElementById(id);

  if (e.target === modalContainer) {
    closeModal(id);
  }
};

export const closeModal = (id = null) => {
  let modalContainer = document.getElementById("modal-wrapper");
  if (id) {
    modalContainer = document.getElementById(id);
  }
  document.body.style.overflow = "";
  modalContainer.classList.remove("isVisible");
};

export const openModal = (id = null) => {
  let modalContainer = document.getElementById("modal-wrapper");
  if (id) {
    modalContainer = document.getElementById(id);
  }

  document.body.style.overflow = "hidden";
  modalContainer.classList.add("isVisible");
};

export const Modal = ({ id, children, confirmLabel, confirmAction, confirmActionLoading, cancelLabel }) => (
  <StyledModal
    id={id || "modal-wrapper"}
    className="modal-wrapper"
    onClick={e => handleModalClick(e, id || "modal-wrapper")}
  >
    <div className="modal-container">
      {Children.toArray(children)}
      <div className="modal-control">
        <Button theme="secondary" type="button" onClick={() => closeModal(id || "modal-wrapper")} data-cy="modalCancelButton">
          {cancelLabel}
        </Button>
        <Button onClick={confirmAction} isLoading={confirmActionLoading} data-cy="modalConfirmButton">
          {confirmLabel}
        </Button>
      </div>
    </div>
  </StyledModal>
);

Modal.propTypes = {
  /** Identifiant de la modale (pour pouvoir la fermer si plusieurs existent) */
  id: PropTypes.string,
  /** Contenu de la modale */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  /** Libellé du bouton de confirmation */
  confirmLabel: PropTypes.string,
  /** Fonction à lancer lors du click sur l'action de confirmation*/
  confirmAction: PropTypes.func,
  /** Etat du bouton de confirmation à loading ou non */
  confirmActionLoading: PropTypes.bool,
  /** Libellé du bouton d'annulation */
  cancelLabel: PropTypes.string,
};

Modal.defaultProps = {
  id: null,
  children: null,
  confirmLabel: null,
  confirmAction: null,
  confirmActionLoading: false,
  cancelLabel: null,
};
